<template>
  <div
    class="projects-navigator"
    @click="parentHandleClick">
    <v-navigation-drawer
      ref="primaryDrawer"
      :value="primaryIsOpen"
      :mobile-breakpoint="$vuetify.breakpoint.mobileBreakpoint"
      class="z-9"
      v-bind="drawerParams">
      <div class="d-flex flex-column justify-space-between h-inherit">
        <v-list
          :class="[
            isDesktop ? 'pt-8 gap-8' : 'pt-11 gap-11',
            'pb-0 d-flex flex-column'
          ]">
          <slot
            name="topMenu"
            :sizing="sizing"
            :toggle="toggleComponent" />

          <!-- Compare -->
          <v-list-item v-if="!hideCompare">
            <v-list-item-icon>
              <app-icon-btn
                :disabled="!Boolean(userId)"
                :icon-name="require('@/assets/icons/grid-web-icon.svg')"
                :is-icon="false"
                :show-tooltip="!disableToolTip('compare')"
                data-test="compare_button"
                v-bind="sizing"
                text="Compare"
                wrapper-class="d-flex align-center gap-3"
                right
                @click="Boolean(userId) && toggleComponent('compare')">
                <span
                  v-if="!isDesktop"
                  class="fs-16">Compare</span>
              </app-icon-btn>
            </v-list-item-icon>
          </v-list-item>

          <!-- Issuance / Versions -->
          <v-list-item v-if="showIssuance">
            <v-list-item-icon>
              <app-icon-btn
                :access="canModifyIssuance(role)"
                :disabled="!canModifyIssuance(role).allowed"
                :icon-name="require('@/assets/icons/note-list-icon.svg')"
                :is-icon="false"
                :show-tooltip="!disableToolTip('issuance')"
                :text="canModifyIssuance(role).allowed ? 'Issuance' : 'Upgrade your workspace to gain access to this feature.'"
                data-test="create_issuance_button"
                v-bind="sizing"
                wrapper-class="d-flex align-center gap-3"
                right
                @click="canModifyIssuance(role).allowed && toggleComponent('issuance')">
                <span
                  v-if="!isDesktop"
                  class="fs-16">Issuance</span>
              </app-icon-btn>
            </v-list-item-icon>
          </v-list-item>

          <!-- Export -->
          <v-list-item v-if="showExport">
            <v-list-item-icon>
              <app-icon-btn
                :icon-name="require('@/assets/icons/arrow-export-icon.svg')"
                :is-icon="false"
                :show-tooltip="!disableToolTip('export')"
                v-bind="sizing"
                text="Export"
                wrapper-class="d-flex align-center gap-3"
                right
                @click="toggleComponent('export')">
                <span
                  v-if="!isDesktop"
                  class="fs-16">Export</span>
              </app-icon-btn>
            </v-list-item-icon>
          </v-list-item>

          <!-- Settings -->
          <v-list-item v-if="showSettingsIcon && showHeaderElements && needShowControls">
            <v-list-item-icon>
              <app-icon-btn
                :disabled="!Boolean(userId)"
                :icon-name="require('@/assets/icons/settings-ico.svg')"
                :is-icon="false"
                :show-tooltip="!disableToolTip('settings')"
                v-bind="sizing"
                text="Settings"
                wrapper-class="d-flex align-center gap-3"
                right
                @click="userId ? showProjectInfoModalAction({id: detailProjectData.id, role }) : null">
                <span
                  v-if="!isDesktop"
                  class="fs-16">Settings</span>
              </app-icon-btn>
            </v-list-item-icon>
          </v-list-item>

          <!-- Revit Export -->
          <v-list-item v-if="showExport">
            <v-list-item-icon>
              <app-icon-btn
                :disabled="!isPremiumWorkspace"
                :icon-name="require('@/assets/icons/revit-icon.svg')"
                :is-icon="false"
                :text="isPremiumWorkspace ? 'Revit Export' : 'Upgrade your workspace to gain access to this feature.'"
                v-bind="sizing"
                wrapper-class="d-flex align-center gap-3"
                show-tooltip
                right
                @click="isPremiumWorkspace && (revitDialog = true)">
                <span
                  v-if="!isDesktop"
                  class="fs-16">Revit Export</span>
              </app-icon-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>

        <!-- bottom nav -->
        <v-list
          :class="[ isDesktop ? 'py-8 gap-8' : 'py-11 gap-11', 'd-flex flex-column']">
          <!-- quick export -->
          <v-list-item v-if="isVisiblePrint">
            <app-icon-btn
              :icon-name="require('@/assets/icons/print-icon.svg')"
              :is-icon="false"
              :icon-height="isDesktop ? 16 : 30"
              :icon-width="isDesktop ? 16 : 30"
              text="Quick Export"
              wrapper-class="d-flex align-center gap-3"
              show-tooltip
              right
              @click="externalPrint">
              <span
                v-if="!isDesktop"
                class="fs-16">Quick Export</span>
            </app-icon-btn>
          </v-list-item>

          <!-- share project -->
          <v-list-item v-if="showHeaderElements && needShowControls">
            <app-icon-btn
              :icon-name="require('@/assets/icons/share-icon.svg')"
              :is-icon="false"
              :icon-height="isDesktop ? 16 : 30"
              :icon-width="isDesktop ? 16 : 30"
              text="Share"
              wrapper-class="d-flex align-center gap-3"
              show-tooltip
              right
              @click="onShareModalAction">
              <span
                v-if="!isDesktop"
                class="fs-16">Share</span>
            </app-icon-btn>
          </v-list-item>
          <slot name="bottomMenu" />
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      ref="secondaryDrawer"
      :value="Boolean(componentType)"
      class="secondary-drawer"
      v-bind="secondayDrawer"
      absolute
      stateless
      temporary>
      <v-card class="h-inherit d-flex flex-column align-stretch">
        <v-card-title class="px-6 mb-7 fs-14 text-uppercase darkGrey--text text--darken-3">
          {{ computeTitle }}
        </v-card-title>

        <v-card-text class="pa-0 flex-grow-1">
          <component
            :is="computeComponent"
            v-if="computeComponent"
            v-bind="childProps"
            class="h-inherit"
            v-on="$listeners"
            @closeDrawer="toggleComponent(null)" />
        </v-card-text>

        <v-card-actions
          class="px-6 py-9 pointer"
          @click="toggleComponent(null)">
          <v-icon
            color="black"
            size="30">
            mdi-arrow-left-circle
          </v-icon>
          <span class="fs-16 ml-3 vertical-middle black--text">
            Minimize Menu
          </span>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <v-dialog
      v-model="revitDialog"
      width="500">
      <v-card>
        <v-card-title>
          Export to Revit
          <span
            class="sourcery__icon-wrapper black"
            @click="revitDialog = false">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text class="text-center">
          <div class="fs-18">
            Export this view to Revit
          </div>
          <div class="py-3">
            Click the button below to create a Revit compatible CSV from the current view your project is on.
          </div>
          <v-btn
            color="blue"
            class="white--text elevation-0"
            @click="exportRevitCSV">
            Export
          </v-btn>
        </v-card-text>
        <v-card-actions class="fs-12 justify-center">
          Click <a
            class="text-decoration-underline"
            href="https://netorgft7339845-my.sharepoint.com/:u:/g/personal/pboken_gosourcery_com/EWwL7FY-VUlMrKpvVoMMpvgB9xVzy2-NEObVF-v5KTBFCA?e=ktvDzD"
            target="_blank">&nbsp;here&nbsp;</a> to download the Sourcery app from Revit Store
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';

import ExportProject from '@/mixins/ExportProject';

export default {
  name: 'ProjectDetailsNavigation',
  components: {
    ProjectDetailsCompareViews: () => import('@/components/ProjectDetails/ProjectDetailsCompareViews'),
    ProjectDetailsCreateIssuance: () => import('@/components/ProjectDetails/ProjectDetailsCreateIssuance'),
    ProjectDetailsExportTo: () => import('@/components/ProjectDetails/ProjectDetailsExportTo'),
  },
  mixins: [ExportProject],
  props: {
    childProps: {
      type: Object,
      default: () => {
      },
    },
    hideCompare: {
      type: Boolean,
      default: false,
    },
    primaryIsOpen: {
      type: Boolean,
      default: false,
    },
    needShowControls: {
      type: Boolean,
      default: true,
    },
    showExport: {
      type: Boolean,
      default: true,
    },
    showIssuance: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      default: null,
    },
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    revitDialog: false,
  }),
  computed: {
    ...mapGetters({
      canModifyIssuance: 'UserRoles/canModifyIssuance',
    }),
    ...mapState(['role']),
    ...mapState({
      componentType: state => state.ProjectDetails.sidebarComponent,
    }),
    computeComponent() {
      let comp = null;
      switch (this.componentType) {
      case 'export':
        comp = 'ProjectDetailsExportTo';
        break;
      case 'issuance':
        comp = 'ProjectDetailsCreateIssuance';
        break;
      case 'compare':
        comp = 'ProjectDetailsCompareViews';
        break;
      }
      return comp;
    },
    computeTitle() {
      let comp = '';
      switch (this.componentType) {
      case 'export':
        comp = 'Export';
        break;
      case 'issuance':
        comp = 'Versions';
        break;
      case 'compare':
        comp = 'Compare';
        break;
      }
      return comp;
    },
    drawerParams() {
      return this.isDesktop
        ? {
          absolute: true,
          permanent: true,
          width: '90',
        } : {
          absolute: true,
          right: true,
          stateless: true,
          temporary: true,
          width: '100%',
        };
    },
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile;
    },
    secondayDrawer() {
      return this.isDesktop
        ? {
          width: '390px',
        } : {
          right: true,
          width: '100%',
        };
    },
    sizing() {
      let size = 30;

      if (this.$vuetify.breakpoint.lgOnly) {
        size = 24;
      }

      return {
        'icon-height': size,
        'icon-width': size,
      };
    },
    showHeaderElements() {
      return ['schedule-library'].includes(this.$route.name) && this.detailProjectData !== null && this.userInfo;
    },
    showSettingsIcon() {
      return this.detailProjectData?.status === 'active';
    },
  },
  watch: {
    primaryIsOpen(nVal) {
      if (!nVal) {
        this.toggleComponent();
      }
    },
  },
  methods: {
    ...mapMutations(['showProjectInfoModalAction', 'showShareModalAction']),
    ...mapMutations('ProjectDetails', ['setSidebarComponent']),
    disableToolTip(component) {
      if (!this.isDesktop) return true;
      if (this.componentType === component) return true;
      return false;
    },
    exportRevitCSV() {
      this.revitDialog = false;
      this.exportProjectHandle({
        name: 'Current view',
        templateName: '',
        fileFormat: this.revitObj.name,
        exportType: this.revitObj.value,
      });
    },
    onShareModalAction() {
      this.showShareModalAction();
    },
    parentHandleClick(event) {
      const primaryDrawer = this.$refs.primaryDrawer.$el;
      const secondaryDrawer = this.$refs.secondaryDrawer?.$el;
      if (primaryDrawer?.contains(event?.target) || secondaryDrawer?.contains(event?.target)) return;

      this.toggleComponent(null); // Close the secondary drawer
    },
    toggleComponent(val) {
      this.setSidebarComponent(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.projects-navigator {
  ::v-deep.v-navigation-drawer {
    &__border {
      border-right: 1px solid rgba(12, 12, 12, 0.25);
    }
  }

  ::v-deep .v-list-item {
    &:not(.secondary-drawer .v-list-item) {
      min-height: unset;

      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        justify-content: center;
      }
    }

    &__icon {
      margin: 0 !important;
      justify-content: center;

      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        margin: 0 auto !important;
      }
    }
  }

  .secondary-drawer {
    box-shadow: none;
    z-index: 10;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      z-index: 8;
      left: 90px;
    }
  }
}
</style>